<template>
  <div class="page">
    <p>
      <b>{{appName}}App内嵌SDK名单</b>
      <br>
    </p>
    <p class="content">为保障{{appName}}App相关功能的实现与应用的稳定运行，我们可能会接入由第三方提供的软件开发包（
      SDK）以实现相关目的。不同版本的第三方SDK会有所不同，
      一般包括一键登录类、第三方账号登录类、推送通知消息类、第三方支付类、地图导航类、厂商推送类、分享类、统计类等。
      我们会对合作方获取信息的软件工具开发包（SDK）进行严格的安全监测，以保障数据安全。您可以通过以下链接查看第三方的数据使用和保护规则。
      <br>
    </p>
    <table v-if="!isIos">
      <colgroup>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
      </colgroup>
      <thead>
        <tr></tr>
      </thead>
      <tbody>
        <tr>
          <td style="text-align: center;">第三方公司名称</td>
          <td style="text-align: center;">产品/服务</td>
          <td style="text-align: center;">涉及个人信息</td>
          <td style="text-align: center;">使用目的</td>
          <td style="text-align: center;">使用场景</td>
          <td style="text-align: center;">共享方式</td>
          <td style="text-align: center;">第三方链接</td>
        </tr>
        <tr>
          <td>友盟同欣(北京)科技有限公司</td>
          <td style="text-align: center;">友盟+</td>
          <td style="text-align: center;">设备信息（IMEI/Mac/Android ID
/IDFA/OPENUDID/GUID/SIM 卡 IMSI /地理位置/设备序列号等）</td>
          <td style="text-align: center;">用户使用分享</td>
          <td style="text-align: center;">一键分享服务、统计分析服务并通过地理位置校准报表数据准确性，提供基础反作弊能力</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：<a href="https://www.umeng.com/"  rel="nofollow">https://www.umeng.com/</a>
            <br>官方隐私政策：<a href="https://www.umeng.com/page/policy" rel="nofollow">https://www.umeng.com/page/policy</a>
          </td>
        </tr>
        <tr>
          <td>每日互动股份有限公司</td>
          <td style="text-align: center;">个验</td>
          <td style="text-align: center;">设备信息（IMEI/Mac/Android ID
/IDFA/OPENUDID/GUID/SIM 卡 IMSI /地理位置/设备序列号等）</td>
          <td style="text-align: center;">用户一键登录</td>
          <td style="text-align: center;">用户使用快捷一键登录功能</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://www.getui.com/" rel="nofollow">https://www.getui.com/</a>
            <br>官方隐私政策：<a href="https://docs.getui.com/privacy/" rel="nofollow">https://docs.getui.com/privacy/</a>
          </td>
        </tr>
        <tr>
          <td>浙江淘宝网络有限公司</td>
          <td style="text-align: center;">阿里百川</td>
          <td style="text-align: center;">本机应用列表、设备Mac地址、唯一设备识别码
            （IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息/设备序列号）、运营商信息、网络状态信息、操作日志</td>
          <td style="text-align: center;">跳转手机打开淘宝</td>
          <td style="text-align: center;">支持跳转到手机淘宝打开商品详情</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://baichuan.taobao.com/" rel="nofollow">https://baichuan.taobao.com/</a>
            <br>官方隐私政策:
            <br>
            <a href="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html" rel="nofollow">https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html</a>
          </td>
        </tr>
        <tr>
          <td>北京微梦创科网络技术有限公司</td>
          <td style="text-align: center;">新浪微博</td>
          <td style="text-align: center;">设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、
            SIM 卡 IMSI 信息/设备序列号）、
            需要分享的社交账户公开信息</td>
          <td style="text-align: center;">微博分享</td>
          <td style="text-align: center;">支持微博分享服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">
            <a target="_blank" href="https://security.feishu.cn/link/safety?target=https%3A%2F%2Fm.weibo.cn%2Fc%2Fprivacy&amp;scene=ccm&amp;logParams=%7B%22location%22%3A%22drivesdk_creation%22%7D&amp;lang=zh-CN">官网链接：
              https://m.weibo.cn 官方隐私政策：https://m.weibo.cn/c/privacy</a>
          </td>
        </tr>
        <tr>
          <td>高德软件有限公司</td>
          <td style="text-align: center;">高德地图</td>
          <td style="text-align: center;">服务所获取的“位置信息”，包括GNSS信息、IP地址、WLAN热点、基站信息、
            蓝牙设备以及其他能够用于定位的传感器信息；服务所获取的“设备信息”，包括终端设备唯一标识符
            （包含IDFV、Android ID、OAID、IMEI、MEID、IMSI/设备序列号等）、mac地址、操作系统版本、
            设备品牌及型号、设备配置、运营商类型、应用信息（应用名、应用版本号）等</td>
          <td style="text-align: center;">获取位置信息</td>
          <td style="text-align: center;">记一笔账时向您提供位置账单地图服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://lbs.amap.com/" rel="nofollow">https://lbs.amap.com/</a>
            <br>官方隐私政策:
            <br>
            <a href="https://lbs.amap.com/pages/privacy/" rel="nofollow">https://lbs.amap.com/pages/privacy/</a>
          </td>
        </tr>
        <!-- <tr>
          <td>百度</td>
          <td style="text-align: center;">百度定位SDK</td>
          <td style="text-align: center;">服务所获取的“位置信息”，包括GNSS信息、IP地址、WLAN热点、基站信息、
            蓝牙设备以及其他能够用于定位的传感器信息；服务所获取的“注册信息”，包括百度平台通用账号；
            服务所获取的“设备信息”，包括终端设备唯一标识符（包含IDFV、Android ID、OAID、IMEI、MEID、IMSI/设备序列号等）、
            mac地址、操作系统版本、设备品牌及型号、设备配置、运营商类型、应用信息（应用名、应用版本号）等</td>
          <td style="text-align: center;">获取位置信息</td>
          <td style="text-align: center;">记一笔账时向您提供位置账单地图服务，会根据sdk使用反馈与高德地图切换，达到定位最准确目的</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://lbsyun.baidu.com/" rel="nofollow">https://lbsyun.baidu.com/</a>
            <br>官方隐私政策
            <br>
            <a href="https://map.baidu.com/zt/client/privacy/index.html" rel="nofollow">https://map.baidu.com/zt/client/privacy/index.html</a>
          </td>
        </tr> -->
        <tr>
          <td>科大讯飞股份有限公司</td>
          <td style="text-align: center;">讯飞语音SDK</td>
          <td style="text-align: center;">本机设备Mac地址、
            唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息/设备序列号）、
            运营商信息、网络状态信息等</td>
          <td style="text-align: center;">语音记账</td>
          <td style="text-align: center;">点击首页语音记账按钮提供语音记账服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://www.xfyun.cn" rel="nofollow">https://www.xfyun.cn</a>
            <br>官方隐私政策
            <br>
            <a href="https://www.xfyun.cn/doc/policy/privacy.html" rel="nofollow">https://www.xfyun.cn/doc/policy/privacy.html</a>
          </td>
        </tr>
        <tr>
          <td>百度</td>
          <td style="text-align: center;">百度语音SDK</td>
          <td style="text-align: center;">服务所获取的“设备信息”，包括终端设备唯一标识符
            （包含IDFV、Android ID、OAID、IMEI、MEID、IMSI、设备序列号等）、
            mac地址、操作系统版本、设备品牌及型号、设备配置、运营商类型、应用信息（应用名、应用版本号）等</td>
          <td style="text-align: center;">语音记账</td>
          <td style="text-align: center;">点击首页语音记账按钮提供语音记账服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://ai.baidu.com/" rel="nofollow">https://ai.baidu.com/</a>
            <br>官方隐私政策
            <br>
            <a href="https://ai.baidu.com/ai-doc/REFERENCE/Qkdykq1r3" rel="nofollow">https://ai.baidu.com/ai-doc/REFERENCE/Qkdykq1r3</a>
          </td>
        </tr>
        <tr>
          <td>腾讯云计算（北京）有限责任公司</td>
          <td style="text-align: center;">腾讯云SDK</td>
          <td style="text-align: center;">本机设备Mac地址、唯一设备识别码
            （IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息/设备序列号）、运营商信息、网络状态信息等</td>
          <td style="text-align: center;">数据分析服务</td>
          <td style="text-align: center;">数据分析服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://cloud.tencent.com/" rel="nofollow">https://cloud.tencent.com/</a>
            <br>官方隐私政策
            <br>
            <a href="https://cloud.tencent.com/document/product/301/11470" rel="nofollow">https://cloud.tencent.com/document/product/301/11470</a>
          </td>
        </tr>
        <tr>
          <td>上海游昆信息技术有限公司</td>
          <td style="text-align: center;">MobTech</td>
          <td style="text-align: center;">服务所获取的“设备信息”，包括终端设备唯一标识符
            （包含IDFV、Android ID、OAID、IMEI、MEID、IMSI、设备序列号等）、mac地址、
            操作系统版本、设备品牌及型号、设备配置、运营商类型、应用信息（应用名、应用版本号）等</td>
          <td style="text-align: center;">分享</td>
          <td style="text-align: center;">app内分享功能</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://www.mob.com/" rel="nofollow">https://www.mob.com/</a>
            <br>官方隐私政策
            <br>
            <a href="http://download.sdk.mob.com/1c8/059/afe3e81cf9b4f38fa06a776296/50.17.html" rel="nofollow">http://download.sdk.mob.com/1c8/059/afe3e81cf9b4f38fa06a776296/50.17.html</a>
          </td>
        </tr>
        <tr>
          <td>深圳市腾讯计算机系统有限公司</td>
          <td style="text-align: center;">bugly</td>
          <td style="text-align: center;">设备Mac地址、唯一设备识别码
            （IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息、设备序列号）</td>
          <td style="text-align: center;">崩溃收集服务</td>
          <td style="text-align: center;">app奔溃日志统计</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://bugly.qq.com/v2/" rel="nofollow">https://bugly.qq.com/v2/</a>
            <br>官方隐私政策
            <br>
            <a href="https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf" rel="nofollow">https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>优视科技（中国）有限公司</td>
          <td style="text-align: center;">啄木鸟</td>
          <td style="text-align: center;">设备信息（IMEI，Mac地址，手机序列号、设备序列号），收集读取权限；</td>
          <td style="text-align: center;">崩溃收集服务</td>
          <td style="text-align: center;">崩溃收集服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://open-uc.uc.cn/solution/zhuomuniao" rel="nofollow">https://open-uc.uc.cn/solution/zhuomuniao</a>
            <br>官方隐私政策
            <br>
            <a href="https://open-uc.uc.cn/agreement/secret" rel="nofollow">https://open-uc.uc.cn/agreement/secret</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>深圳市腾讯计算机系统有限公司</td>
          <td style="text-align: center;">腾讯</td>
          <td style="text-align: center;">设备信息（国际设备识别码（IMEI、SSID、BSSID），国际移动用户识别码（IMSI）、
            网卡地址（MAC地址）、用户机型、屏幕尺寸、操作系统版本号、
            目标API（targetAPI）、网络类型(ApnType)、网络变化监听广播以及 Wifi的MAC地址、设备序列号），存储设备。</td>
          <td style="text-align: center;">优化网页加载速度</td>
          <td style="text-align: center;">App内加载网页</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://x5.tencent.com/" rel="nofollow">https://x5.tencent.com/</a>
            <br>官方隐私政策
            <br>
            <a href="https://x5.tencent.com/docs/privacy.html" rel="nofollow">https://x5.tencent.com/docs/privacy.html</a>
          </td>
        </tr>
        <tr>
          <td>和讯华谷信息技术有限公司</td>
          <td style="text-align: center;">极光</td>
          <td style="text-align: center;">设备信息（国际设备识别码（IMEI、SSID、BSSID、IDFA、
            Android ID、GAID、MAC、OAID、IMSI、MEID、UAID），
            国际移动用户识别码（IMSI）、网卡地址（MAC地址）、用户机型、屏幕尺寸、
            操作系统版本号、目标API（targetAPI）、网络类型(ApnType)、网络变化监听广播以及 Wifi的MAC地址、设备序列号），存储设备以及手机号等</td>
          <td style="text-align: center;">一键登录服务</td>
          <td style="text-align: center;">一键登录服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://www.jiguang.cn" rel="nofollow">https://www.jiguang.cn</a>
            <br>官方隐私政策
            <br>
            <a href="https://www.jiguang.cn/license/privacy" rel="nofollow">https://www.jiguang.cn/license/privacy</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>北京巨量引擎网络技术有限公司</td>
          <td style="text-align: center;">穿山甲SDK（com.bytedance）</td>
          <td style="text-align: center;">设备Mac地址、读写手机外置存储信息、
            唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息、设备序列号）、
            运营商信息、网络状态信息、设备运行进程信息、陀螺仪传感器、加速度传感器、重力传感器</td>
          <td style="text-align: center;">提供效果广告相关服务</td>
          <td style="text-align: center;">开屏启动广告页面</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://ad.oceanengine.com/union/media/union/" rel="nofollow">https://ad.oceanengine.com/union/media/union/</a>
            <br>官方隐私政策
            <br>
            <a href="https://www.csjplatform.com/privacy" rel="nofollow">https://www.csjplatform.com/privacy</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>北京快手科技有限公司</td>
          <td style="text-align: center;">快手广告</td>
          <td style="text-align: center;">设备版本、系统版本、生成ID、
            手机样式、手机型号、iccid、bssid、MAC地址、IMSI、IMEI、软件列表、设备序列号、android ID</td>
          <td style="text-align: center;">广告相关服务</td>
          <td style="text-align: center;">开屏启动广告页面</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://ad.oceanengine.com" rel="nofollow">https://ad.oceanengine.com</a>
            <br>官方隐私政策
            <br>
            <a href="https://ad.oceanengine.com/union/media/union/" rel="nofollow">https://ad.oceanengine.com/union/media/union/</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>个推PUSH-SDK</td>
          <td style="text-align: center;">个推</td>
          <td style="text-align: center;">设备标识符、网络设备硬件地址、运营商信息、网络状态信息</td>
          <td style="text-align: center;">消息推送服务</td>
          <td style="text-align: center;">消息推送服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://sogame.kuaishou.com" rel="nofollow">https://sogame.kuaishou.com</a>
            <br>官方隐私政策
            <br>
            <a href="https://sogame.kuaishou.com/about/androidpolicy" rel="nofollow">https://sogame.kuaishou.com/about/androidpolicy</a>
          </td>
        </tr>
        <tr>
          <td>深圳市腾讯计算机系统有限公司</td>
          <td style="text-align: center;">QQ</td>
          <td style="text-align: center;">网络访问权限、查看WLAN连接、
            获取粗略位置、获取精确位置、读取外置存储卡、写入外部存储卡、读取手机状态和身份、检索正在运行的应用、查询所有的应用</td>
          <td style="text-align: center;">支持QQ分享服务</td>
          <td style="text-align: center;">支持QQ分享服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://open.tencent.com/" rel="nofollow">https://open.tencent.com/</a>
            <br>官方隐私政策
            <br>
            <a href="https://www.tencent.com/zh-cn/privacy-policy.html" rel="nofollow">https://www.tencent.com/zh-cn/privacy-policy.html</a>
          </td>
        </tr>
        <tr>
          <td>深圳市腾讯计算机系统有限公司</td>
          <td style="text-align: center;">微信 SDK</td>
          <td style="text-align: center;">本机应用列表、设备型号、操作系统、设备Mac地址、
            唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、
            SIM 卡 IMSI 信息、设备序列号）、登录IP地址、微信软件版本号、接入网络的方式、类型和状态、网络质量数据、操作日志
            <br>
          </td>
          <td style="text-align: center;">支持微信登录、分享、支付服务</td>
          <td style="text-align: center;">支持微信登录、分享、支付服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://open.weixin.qq.com/" rel="nofollow">https://open.weixin.qq.com/</a>
            <br>官方隐私政策
            <br>
            <a href="https://www.tencent.com/zh-cn/privacy-policy.html" rel="nofollow">https://www.tencent.com/zh-cn/privacy-policy.html</a>
          </td>
        </tr>
        <tr>
          <td>支付宝（中国）网络技术有限公司</td>
          <td style="text-align: center;">支付宝</td>
          <td style="text-align: center;">设备Mac地址、唯一设备识别码
            （IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息、设备序列号）、运营商信息、网络状态信息、操作日志</td>
          <td style="text-align: center;">帮助用户在应用内使用支付宝</td>
          <td style="text-align: center;">帮助用户在应用内使用支付宝</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://opendocs.alipay.com" rel="nofollow">https://opendocs.alipay.com</a>
            <br>官方隐私政策
            <br>
            <a href="https://opendocs.alipay.com/open/54" rel="nofollow">https://opendocs.alipay.com/open/54</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>广州市蜂擎信息科技有限公司</td>
          <td style="text-align: center;">好单库</td>
          <td style="text-align: center;">本机应用列表、设备Mac地址、唯一设备识别码
            （IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息、设备序列号）、运营商信息、网络状态信息、操作日志</td>
          <td style="text-align: center;">使用【好单库】服务，支持商品购买</td>
          <td style="text-align: center;">我的页面使用购物领券</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="http://bianxian.haodanku.com?invite_code=35c319635d" rel="nofollow">http://bianxian.haodanku.com?invite_code=35c319635d</a>
            <br>官方隐私政策
            <br>
            <a href="https://bianxian.haodanku.com/index/privacy" rel="nofollow">https://bianxian.haodanku.com/index/privacy</a>
          </td>
        </tr>

        <tr v-if="appType === 1">
          <td>百度时代网络技术（北京）有限公司</td>
          <td style="text-align: center;">百度广告</td>
          <td style="text-align: center;">设备信息：设备品牌、型号、软件系统版本、
            分辨率、网络信号强度、传感器信息，磁盘总空间、系统总内存空间、
            手机重启信息、手机系统更新时间等基础信息、OAID、AndroidID（仅Andriod端采集）</td>
          <td style="text-align: center;">广告相关服务</td>
          <td style="text-align: center;">开屏启动广告页面</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">
            <a href="https://union.baidu.com/bqt/#/官方隐私政策https://union.baidu.com/bqt/#/legal/policies">官网链接:
              <br>https://union.baidu.com/bqt/#/
              <br>官方隐私政策https://union.baidu.com/bqt/#/legal/policies</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>北京鸿途信达科技股份有限公司</td>
          <td style="text-align: center;">鸿途广告</td>
          <td style="text-align: center;">设备信息（操作系统及版本，设备型号，设备厂商，
            屏幕宽度、高度及屏幕密度，手机IMEI、MAC地址、SIM卡IMSI、Android_id、设备序列号）、位置信息</td>
          <td style="text-align: center;">广告相关服务</td>
          <td style="text-align: center;">开屏启动广告页面</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">
            <a href="https://www.hongtuidc.com/">官网链接:https://www.hongtuidc.com/
              <br>官方隐私政策：https://www.hongtuidc.com/privacy.html</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>深圳市腾讯计算机系统有限公司</td>
          <td style="text-align: center;">优量汇</td>
          <td style="text-align: center;">1.【设备信息】：系统版本名、系统版本号、设备型号、
            分辨率、屏幕DPI设备生产商、网络类型、系统语言、时区、时间戳、User Agent信息、
            屏幕方向、设备序列号; 2.【应用信息】当前应用包名、应用版本名、应用版本信息
             3.【设备标识信息】设备id(android_id)、IMEI（用户授权才收集）、OAID、Mac地址、IDFA 4. 地理位置信息、广告交付数据</td>
          <td style="text-align: center;">广告相关服务</td>
          <td style="text-align: center;">开屏启动广告页面</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://adnet.qq.com/index" rel="nofollow">https://adnet.qq.com/index</a>
            <br>官方隐私政策
            <br>
            <a href="https://imgcache.qq.com/gdt/cdn/adn/uniondoc/ylh_sdk_privacy_statement.html"
            rel="nofollow">https://imgcache.qq.com/gdt/cdn/adn/uniondoc/ylh_sdk_privacy_statement.html</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>北京小度互娱科技有限公司</td>
          <td style="text-align: center;">百度联盟</td>
          <td style="text-align: center;">设备品牌、设备类型、硬件型号、设备ID、操作系统版本号、
            国际移动设备识别码（IMEI）、国际移动用户识别码（IMSI）、
            网络设备硬件地址（MAC）、IP地址、软件版本号、网络接入方式及类型、匿名设备标识符（OAID）、屏幕参数、IDFA及IDFV等信息</td>
          <td style="text-align: center;">广告相关服务</td>
          <td style="text-align: center;">开屏启动广告页面</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://union.baidu.com/bqt" rel="nofollow">https://union.baidu.com/bqt</a>
            <br>官方隐私政策
            <br>
            <a href="http://m.v.xiaodutv.com/about/privacy" rel="nofollow">http://m.v.xiaodutv.com/about/privacy</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>上海闳璟网络科技有限公司</td>
          <td style="text-align: center;">广告服务</td>
          <td style="text-align: center;">设备品牌、设备类型、硬件型号、设备ID、操作系统版本号、国际移动设备识别码（IMEI）、
            国际移动用户识别码（IMSI）、网络设备硬件地址（MAC）、IP地址、软件版本号、网络接入方式及类型、匿名设备标识符（OAID）、屏幕参数、IDFA及IDFV等信息</td>
          <td style="text-align: center;">广告相关服务</td>
          <td style="text-align: center;">开屏启动广告页面</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="http://www.zaibiao.cn" rel="nofollow">http://www.zaibiao.cn</a>
            <br>官方隐私政策
            <br>
            <a href="http://www.zaibiao.cn/privacy.htm" rel="nofollow">http://www.zaibiao.cn/privacy.htm</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>北京鑫物科技有限公司</td>
          <td style="text-align: center;">Π金引擎</td>
          <td style="text-align: center;">设备Mac地址、唯一设备识别码
            （IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息、设备序列号）、运营商信息、网络状态信息</td>
          <td style="text-align: center;">广告相关服务</td>
          <td style="text-align: center;">开屏启动广告页面</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://www.xwuad.com/" rel="nofollow">https://www.xwuad.com/</a>
            <br>官方隐私政策
            <br>
            <a href="https://www.xwuad.com/about/privacy.html" rel="nofollow">https://www.xwuad.com/about/privacy.html</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>小米科技有限责任公司</td>
          <td style="text-align: center;">com.xiaomi.mipush(小米;小米推送)</td>
          <td style="text-align: center;">设备标识符、网络设备硬件地址、运营商信息、网络状态信息</td>
          <td style="text-align: center;">消息推送服务</td>
          <td style="text-align: center;">消息推送服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://www.mi.com/" rel="nofollow">https://www.mi.com/</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>小米科技有限责任公司</td>
          <td style="text-align: center;">com.xiaomi.push(小米;小米推送)</td>
          <td style="text-align: center;">设备标识符、网络设备硬件地址、运营商信息、网络状态信息</td>
          <td style="text-align: center;">消息推送服务</td>
          <td style="text-align: center;">消息推送服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://www.mi.com/" rel="nofollow">https://www.mi.com/</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>OPPO广东移动通信有限公司</td>
          <td style="text-align: center;">com.heytap.mcs(OPPO;OPPO推送) </td>
          <td style="text-align: center;">设备标识符、网络设备硬件地址、运营商信息、网络状态信息</td>
          <td style="text-align: center;">消息推送服务</td>
          <td style="text-align: center;">消息推送服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://www.oppo.com/cn/" rel="nofollow">https://www.oppo.com/cn/</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>OPPO广东移动通信有限公司</td>
          <td style="text-align: center;">com.heytap.msp(OPPO;OPPO推送）</td>
          <td style="text-align: center;">设备标识符、网络设备硬件地址、运营商信息、网络状态信息</td>
          <td style="text-align: center;">消息推送服务</td>
          <td style="text-align: center;">消息推送服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://www.oppo.com/cn/" rel="nofollow">https://www.oppo.com/cn/</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>深圳市极光科技有限公司</td>
          <td style="text-align: center;">cn.jpush.android(极光;极光推送) </td>
          <td style="text-align: center;">设备标识符、网络设备硬件地址、运营商信息、网络状态信息</td>
          <td style="text-align: center;">消息推送服务</td>
          <td style="text-align: center;">消息推送服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://www.jiguang.cn" rel="nofollow">https://www.jiguang.cn</a>
            <br>官方隐私政策
            <br>
            <a href="https://www.jiguang.cn/license/privacy" rel="nofollow">https://www.jiguang.cn/license/privacy</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>每日互动股份有限公司</td>
          <td style="text-align: center;">com.getui(个推;个数应用统计;个像)  </td>
          <td style="text-align: center;">设备信息（IMEI/Mac/Android ID/IDFA/OPENUDID/GUID/SIM 卡 IMSI
            /地理位置/设备序列号等）</td>
          <td style="text-align: center;">用户一键登录</td>
          <td style="text-align: center;">用户使用快捷一键登录功能</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://www.getui.com/" rel="nofollow">https://www.getui.com/</a>
            <br>官方隐私政策
            <br>
            <a href="https://docs.getui.com/privacy/" rel="nofollow">https://docs.getui.com/privacy/</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>每日互动股份有限公司</td>
          <td style="text-align: center;">com.g.gysdk(个推) </td>
          <td style="text-align: center;">设备信息（IMEI/Mac/Android ID/IDFA/OPENUDID/GUID/SIM 卡 IMSI
            /地理位置/设备序列号等）</td>
          <td style="text-align: center;">用户一键登录</td>
          <td style="text-align: center;">用户使用快捷一键登录功能</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://www.getui.com/" rel="nofollow">https://www.getui.com/</a>
            <br>官方隐私政策
            <br>
            <a href="https://docs.getui.com/privacy/" rel="nofollow">https://docs.getui.com/privacy/</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>网易公司</td>
          <td style="text-align: center;">com.netease(网易;网易IM;网易云信) </td>
          <td style="text-align: center;">设备识别信息</td>
          <td style="text-align: center;">数据分析服务</td>
          <td style="text-align: center;">方便更好的了解用户行为以提升用户体验</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://www.163yun.com/" rel="nofollow">https://www.163yun.com/</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>新浪微博</td>
          <td style="text-align: center;">com.sina.weibo(微博;新浪微博) </td>
          <td style="text-align: center;">设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、
            SIM 卡 IMSI 信息/设备序列号）、需要分享的社交账户公开信息</td>
          <td style="text-align: center;">微博分享</td>
          <td style="text-align: center;">支持微博分享服务</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://m.weibo.cn" rel="nofollow">https://m.weibo.cn</a>
            <br>官方隐私政策
            <br>
            <a href="https://m.weibo.cn/c/privacy" rel="nofollow">https://m.weibo.cn/c/privacy</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>深圳市腾讯计算机系统有限公司</td>
          <td style="text-align: center;">com.tencent.smtt(TBS腾讯浏览服务;腾讯浏览服务;腾讯X5浏览器;腾讯浏览器) </td>
          <td style="text-align: center;">设备识别信息</td>
          <td style="text-align: center;">数据分析服务</td>
          <td style="text-align: center;">方便更好的了解用户行为以提升用户体验</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://tencent.com" rel="nofollow">https://tencent.com</a>
          </td>
        </tr>
        <tr>
          <td>深圳市腾讯计算机系统有限公司</td>
          <td style="text-align: center;">com.tencent.stat(腾讯移动分析) </td>
          <td style="text-align: center;">设备识别信息</td>
          <td style="text-align: center;">数据分析服务</td>
          <td style="text-align: center;">方便更好的了解用户行为以提升用户体验</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://tencent.com" rel="nofollow">https://tencent.com</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>深圳市腾讯计算机系统有限公司</td>
          <td style="text-align: center;">com.tencent.open(腾讯开放平台) </td>
          <td style="text-align: center;">设备识别信息</td>
          <td style="text-align: center;">数据分析服务</td>
          <td style="text-align: center;">方便更好的了解用户行为以提升用户体验</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://tencent.com" rel="nofollow">https://tencent.com</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>深圳市腾讯计算机系统有限公司</td>
          <td style="text-align: center;">com.tencent.tauth(腾讯开放平台)</td>
          <td style="text-align: center;">设备识别信息</td>
          <td style="text-align: center;">数据分析服务</td>
          <td style="text-align: center;">方便更好的了解用户行为以提升用户体验</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://tencent.com" rel="nofollow">https://tencent.com</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>深圳市腾讯计算机系统有限公司</td>
          <td style="text-align: center;">com.tencent.bugly(Bugly;腾讯Bugly)</td>
          <td style="text-align: center;">设备识别信息</td>
          <td style="text-align: center;">数据分析服务</td>
          <td style="text-align: center;">方便更好的了解用户行为以提升用户体验</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://tencent.com" rel="nofollow">https://tencent.com</a>
          </td>
        </tr>
        <!-- <tr v-if="appType === 1">
          <td>百度</td>
          <td style="text-align: center;">com.baidu.location(百度地图) </td>
          <td style="text-align: center;">服务所获取的“位置信息”，包括GNSS信息、IP地址、WLAN热点、基站信息、
            蓝牙设备以及其他能够用于定位的传感器信息；服务所获取的“注册信息”，包括百度平台通用账号；
            服务所获取的“设备信息”，包括终端设备唯一标识符（包含IDFV、Android ID、OAID、IMEI、MEID、IMSI/设备序列号等）、
            mac地址、操作系统版本、设备品牌及型号、设备配置、运营商类型、应用信息（应用名、应用版本号）等</td>
          <td style="text-align: center;">获取位置信息</td>
          <td style="text-align: center;">记一笔账时向您提供位置账单地图服务，会根据sdk使用反馈与高德地图切换，达到定位最准确目的</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://lbsyun.baidu.com/" rel="nofollow">https://lbsyun.baidu.com/</a>
            <br>官方隐私政策
            <br>
            <a href="https://map.baidu.com/zt/client/privacy/index.html" rel="nofollow">https://map.baidu.com/zt/client/privacy/index.html</a>
          </td>
        </tr> -->
        <tr>
          <td>中国电信集团有限公司</td>
          <td style="text-align: center;">cn.com.chinatelecom(电信;中国电信)  </td>
          <td style="text-align: center;">设备信息（IMEI/Mac/Android ID
/IDFA/OPENUDID/GUID/SIM 卡 IMSI /地理位置/设备序列号等）</td>
          <td style="text-align: center;">用户一键登录</td>
          <td style="text-align: center;">用户使用快捷一键登录功能</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="http://www.chinatelecom.com.cn/" rel="nofollow">http://www.chinatelecom.com.cn/</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>中国移动通信集团有限公司</td>
          <td style="text-align: center;">com.cmic.sso(移动;中国移动) SDK</td>
          <td style="text-align: center;">设备信息（IMEI/Mac/Android ID
/IDFA/OPENUDID/GUID/SIM 卡 IMSI /地理位置/设备序列号等）</td>
          <td style="text-align: center;">用户一键登录</td>
          <td style="text-align: center;">用户使用快捷一键登录功能</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://cmic.com" rel="nofollow">https://cmic.com</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>支付宝（中国）网络技术有限公司</td>
          <td style="text-align: center;">com.alipay(支付宝;mPaaS;阿里乘车码;阿里芝麻信用实名认证;芝麻认证) </td>
          <td style="text-align: center;">设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、
            SIM 卡 IMSI 信息、设备序列号）、运营商信息、网络状态信息、操作日志</td>
          <td style="text-align: center;">帮助用户在应用内使用支付宝</td>
          <td style="text-align: center;">帮助用户在应用内使用支付宝</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接：
            <br>
            <a href="https://opendocs.alipay.com" rel="nofollow">https://opendocs.alipay.com</a>
            <br>官方隐私政策
            <br>
            <a href="https://opendocs.alipay.com/open/54" rel="nofollow">https://opendocs.alipay.com/open/54</a>
          </td>
        </tr>
        <tr>
          <td>杭州网易易盾科技有限公司</td>
          <td style="text-align: center;">com.netease.nis(网易易盾;移动安全联盟;MSA)SDK</td>
          <td style="text-align: center;">设备信息（IMEI、AndroidID、MAC地址），手机号码，用户账号信息（账号，昵称）</td>
          <td style="text-align: center;">用户一键登录</td>
          <td style="text-align: center;">用户使用快捷一键登录功能</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官方隐私政策：
            <br>
            <a href="https://dun.163.com/clause/privacy" rel="nofollow">https://dun.163.com/clause/privacy</a>
          </td>
        </tr>
        <tr v-if="appType === 1">
          <td>每日互动股份有限公司</td>
          <td style="text-align: center;">个推用户运营SDK</td>
          <td style="text-align: center;">设备信息（IMEI/Mac/Android ID
    /IDFA/OPENUDID/GUID/SIM 卡 IMSI /地理位置/设备序列号等）</td>
          <td style="text-align: center;">用户使用分享</td>
          <td style="text-align: center;">一键分享服务、统计分析服务并通过地理位置校准报表数据准确性，提供基础反作弊能力</td>
          <td style="text-align: center;">SDK本机采集</td>
          <td style="text-align: center;">官网链接:
            <br>
            <a href="https://www.getui.com/" rel="nofollow">https://www.getui.com/</a>
            <br>官方隐私政策
            <br>
            <a href="https://docs.getui.com/privacy/" rel="nofollow">https://docs.getui.com/privacy/</a>
          </td>
        </tr>
        <tr>
      <td>深圳市易售科技有限公司</td>
      <td style="text-align: center;">易盟 SDK</td>
      <td style="text-align: center;">设备信息(IMEI、Android ID、OAID)、Mac地址、
        应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、
        设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息）、
        网络信息：IP地址，WiFi路由器MAC地址（BSSID）、设备的MAC地址，基站信息等相关信息、
        位置信息、陀螺仪传感器信息，加速度传感器信息</td>
      <td style="text-align: center;">用于广告投放</td>
      <td style="text-align: center;">广告投放</td>
      <td style="text-align: center;">SDK本机采集</td>
      <td style="text-align: center;">
        官方隐私政策
        <br>
        <a href="https://www.yeemeng.cc/privacy.html" rel="nofollow">https://www.yeemeng.cc/privacy.html</a>
      </td>
    </tr>
      </tbody>
    </table>
    <table v-else>
	<colgroup>
		<col width="14.285714285714285%">
		<col width="14.285714285714285%">
		<col width="14.285714285714285%">
		<col width="14.285714285714285%">
		<col width="14.285714285714285%">
		<col width="14.285714285714285%">
		<col width="14.285714285714285%">
	</colgroup>
	<thead>
		<tr>
			<th>第三方公司名称</th>
			<th>产品/服务</th>
			<th>涉及个人信息</th>
			<th>使用目的</th>
			<th>使用场景</th>
			<th>共享方式</th>
			<th>第三方链接</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>友盟同欣(北京)科技有限公司</td>
			<td style="text-align: center;">友盟+&nbsp;</td>
			<td style="text-align: center;">设备信息（IMEI/Mac/Android&nbsp;ID
				<br>/IDFA/OPENUDID/GUID/SIM&nbsp;卡&nbsp;IMSI&nbsp;/地理位置等）</td>
			<td style="text-align: center;">用户使用分享</td>
			<td style="text-align: center;">一键分享服务、统计分析服务并通过地理位置校准报表数据准确性，提供基础反作弊能力</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接：<a href="https://www.umeng.com/" rel="nofollow">https://www.umeng.com/</a>
				<br>官方隐私政策：<a href="https://www.umeng.com/page/policy" rel="nofollow">https://www.umeng.com/page/policy</a>
			</td>
		</tr>
		<tr>
			<td>每日互动股份有限公司</td>
			<td style="text-align: center;">个验</td>
			<td style="text-align: center;">设备信息（IMEI/Mac/Android&nbsp;ID
				<br>/IDFA/OPENUDID/GUID/SIM&nbsp;卡&nbsp;IMSI&nbsp;/地理位置等）</td>
			<td style="text-align: center;">用户一键登录</td>
			<td style="text-align: center;">用户使用快捷一键登录功能</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://www.getui.com/" rel="nofollow">https://www.getui.com/</a>
				<br>官方隐私政策：<a href="https://docs.getui.com/privacy/" rel="nofollow">https://docs.getui.com/privacy/</a>
			</td>
		</tr>
		<tr>
			<td>浙江淘宝网络有限公司</td>
			<td style="text-align: center;">阿里百川</td>
			<td style="text-align: center;">本机应用列表、设备Mac地址、
        唯一设备识别码（IMEI/android&nbsp;ID/IDFA/OPENUDID/GUID、SIM&nbsp;卡&nbsp;IMSI&nbsp;信息）、
        运营商信息、网络状态信息、操作日志</td>
			<td style="text-align: center;">跳转手机打开淘宝</td>
			<td style="text-align: center;">支持跳转到手机淘宝打开商品详情</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://baichuan.taobao.com/" rel="nofollow">https://baichuan.taobao.com/</a>
				<br>官方隐私政策:
				<br>
				<a href="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html" rel="nofollow">https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html</a>
			</td>
		</tr>
		<tr>
			<td>北京微梦创科网络技术有限公司</td>
			<td style="text-align: center;">新浪微博</td>
			<td style="text-align: center;">设备Mac地址、唯一设备识别码（IMEI/android&nbsp;ID/IDFA/OPENUDID/GUID、
        SIM&nbsp;卡&nbsp;IMSI&nbsp;信息）、
        需要分享的社交账户公开信息</td>
			<td style="text-align: center;">微博分享</td>
			<td style="text-align: center;">支持微博分享服务</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">
				<a href="https://m.weibo.cn/c/privacy">官网链接：
					<br>https://m.weibo.cn
					<br>官方隐私政策：https://m.weibo.cn/c/privacy</a>
			</td>
		</tr>
		<tr>
			<td>高德软件有限公司</td>
			<td style="text-align: center;">高德地图</td>
			<td style="text-align: center;">服务所获取的“位置信息”，包括GNSS信息、IP地址、WLAN热点、
        基站信息、蓝牙设备以及其他能够用于定位的传感器信息；服务所获取的“设备信息”，
        包括终端设备唯一标识符（包含IDFV、Android ID、OAID、IMEI、MEID、IMSI等）、mac地址、
        操作系统版本、设备品牌及型号、设备配置、运营商类型、应用信息（应用名、应用版本号）等</td>
			<td style="text-align: center;">获取位置信息</td>
			<td style="text-align: center;">记一笔账时向您提供位置账单地图服务</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://lbs.amap.com/" rel="nofollow">https://lbs.amap.com/</a>
				<br>官方隐私政策:
				<br>
				<a href="https://lbs.amap.com/pages/privacy/" rel="nofollow">https://lbs.amap.com/pages/privacy/</a>
			</td>
		</tr>
		<tr>
			<td>科大讯飞股份有限公司</td>
			<td style="text-align: center;">讯飞语音SDK</td>
			<td style="text-align: center;">本机设备Mac地址、
        唯一设备识别码（IMEI/android&nbsp;ID/IDFA/OPENUDID/GUID、SIM&nbsp;卡&nbsp;IMSI&nbsp;信息）、
        运营商信息、网络状态信息等</td>
			<td style="text-align: center;">语音记账</td>
			<td style="text-align: center;">点击首页语音记账按钮提供语音记账服务</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://www.xfyun.cn" rel="nofollow">https://www.xfyun.cn</a>
				<br>官方隐私政策
				<br>
				<a href="https://www.xfyun.cn/doc/policy/privacy.html" rel="nofollow">https://www.xfyun.cn/doc/policy/privacy.html</a>
			</td>
		</tr>
		<tr>
			<td>百度</td>
			<td style="text-align: center;">百度语音SDK</td>
			<td style="text-align: center;">服务所获取的“设备信息”，
        包括终端设备唯一标识符（包含IDFV、Android ID、OAID、IMEI、MEID、IMSI等）、mac地址、
        操作系统版本、设备品牌及型号、设备配置、运营商类型、应用信息（应用名、应用版本号）等</td>
			<td style="text-align: center;">语音记账</td>
			<td style="text-align: center;">点击首页语音记账按钮提供语音记账服务</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://ai.baidu.com/" rel="nofollow">https://ai.baidu.com/</a>
				<br>官方隐私政策
				<br>
				<a href="https://ai.baidu.com/ai-doc/REFERENCE/Qkdykq1r3" rel="nofollow">https://ai.baidu.com/ai-doc/REFERENCE/Qkdykq1r3</a>
			</td>
		</tr>
		<tr>
			<td>深圳市腾讯计算机系统有限公司</td>
			<td style="text-align: center;">bugly</td>
			<td style="text-align: center;">设备Mac地址、唯一设备识别码（IMEI/android&nbsp;ID/IDFA/OPENUDID/GUID、
        SIM&nbsp;卡&nbsp;IMSI&nbsp;信息）</td>
			<td style="text-align: center;">崩溃收集服务</td>
			<td style="text-align: center;">app奔溃日志统计</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://bugly.qq.com/v2/" rel="nofollow">https://bugly.qq.com/v2/</a>
				<br>官方隐私政策
				<br>
				<a href="https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf" rel="nofollow">https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf</a>
			</td>
		</tr>
		<tr>
			<td>优视科技（中国）有限公司</td>
			<td style="text-align: center;">啄木鸟</td>
			<td style="text-align: center;">设备信息（IMEI，Mac地址，手机序列号），收集读取权限；</td>
			<td style="text-align: center;">崩溃收集服务</td>
			<td style="text-align: center;">崩溃收集服务</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://open-uc.uc.cn/solution/zhuomuniao" rel="nofollow">https://open-uc.uc.cn/solution/zhuomuniao</a>
				<br>官方隐私政策
				<br>
				<a href="https://open-uc.uc.cn/agreement/secret" rel="nofollow">https://open-uc.uc.cn/agreement/secret</a>
			</td>
		</tr>
		<tr>
			<td>北京巨量引擎网络技术有限公司</td>
			<td style="text-align: center;">穿山甲</td>
			<td style="text-align: center;">设备Mac地址、唯一设备识别码
        （IMEI/android&nbsp;ID/IDFA/OPENUDID/GUID、SIM&nbsp;卡&nbsp;IMSI&nbsp;信息）、运营商信息、网络状态信息</td>
			<td style="text-align: center;">广告相关服务</td>
			<td style="text-align: center;">开屏启动广告页面</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://ad.oceanengine.com" rel="nofollow">https://ad.oceanengine.com</a>
				<br>官方隐私政策
				<br>
				<a href="https://www.csjplatform.com/privacy" rel="nofollow">https://www.csjplatform.com/privacy</a>
			</td>
		</tr>
		<tr>
			<td>北京快手科技有限公司</td>
			<td style="text-align: center;">快手广告</td>
			<td style="text-align: center;">设备版本、系统版本、生成ID、手机样式、
        手机型号、iccid、bssid、MAC地址、IMSI、IMEI、软件列表、设备序列号、android&nbsp;ID</td>
			<td style="text-align: center;">广告相关服务</td>
			<td style="text-align: center;">开屏启动广告页面</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://ad.oceanengine.com" rel="nofollow">https://ad.oceanengine.com</a>
				<br>官方隐私政策
				<br>
				<a href="https://ad.oceanengine.com/union/media/union/" rel="nofollow">https://ad.oceanengine.com/union/media/union/</a>
			</td>
		</tr>
		<tr>
			<td>深圳市腾讯计算机系统有限公司</td>
			<td style="text-align: center;">优量汇</td>
			<td style="text-align: center;">设备版本、系统版本、生成ID、手机样式、手机型号、iccid、
        bssid、MAC地址、IMSI、IMEI、软件列表、设备序列号、android&nbsp;ID</td>
			<td style="text-align: center;">广告相关服务</td>
			<td style="text-align: center;">开屏启动广告页面</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">
				<a href="https://e.qq.com/dev/help_detail.html?cid=2005&amp;pid=5983">官网链接:
					<br>https://ad.oceanengine.com
					<br>官方隐私政策
					<br>https://ad.oceanengine.com/union/media/union/</a>
			</td>
		</tr>
		<tr>
			<td style="text-align: justify;">北京百度网讯科技有限公司</td>
			<td style="text-align: center;">百青藤&nbsp;SDK</td>
			<td style="text-align: center;">设备版本、系统版本、生成ID、手机样式、手机型号、
        iccid、bssid、MAC地址、IMSI、IMEI、软件列表、设备序列号、android&nbsp;ID</td>
			<td style="text-align: center;">广告相关服务</td>
			<td style="text-align: center;">开屏启动广告页面</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://union.baidu.com/bqt/#/" rel="nofollow">https://union.baidu.com/bqt/#/</a>
				<br>官方隐私政策
				<br>
				<a href="https://union.baidu.com/bqt/#/legal/policies" rel="nofollow">https://union.baidu.com/bqt/#/legal/policies</a>
			</td>
		</tr>
		<tr>
			<td style="text-align: justify;">上海世全网络科技有限公司</td>
			<td style="text-align: center;">tradplus&nbsp;SDK</td>
			<td style="text-align: center;">设备版本、系统版本、生成ID、手机样式、手机型号、
        iccid、bssid、MAC地址、IMSI、IMEI、软件列表、设备序列号、android&nbsp;ID</td>
			<td style="text-align: center;">广告相关服务</td>
			<td style="text-align: center;">开屏启动广告页面</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://docs.tradplusad.com" rel="nofollow">https://docs.tradplusad.com</a>
				<br>官方隐私政策
				<br>
				<a href="https://docs.tradplusad.com/privacy-policy/" rel="nofollow">https://docs.tradplusad.com/privacy-policy/</a>
			</td>
		</tr>
		<tr>
			<td>北京创智汇聚科技有限公司</td>
			<td style="text-align: center;">Sigmob&nbsp;SDK</td>
			<td style="text-align: center;">设备版本、系统版本、生成ID、手机样式、手机型号、
        iccid、bssid、MAC地址、IMSI、IMEI、软件列表、设备序列号、android&nbsp;ID</td>
			<td style="text-align: center;">广告相关服务</td>
			<td style="text-align: center;">开屏启动广告页面</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://www.sigmob.com/" rel="nofollow">https://www.sigmob.com/</a>
				<br>官方隐私政策
				<br>
				<a href="https://www.sigmob.com/policy.html" rel="nofollow">https://www.sigmob.com/policy.html</a>
			</td>
		</tr>
		<tr>
			<td>敏力广告传媒（北京）有限公司&nbsp;</td>
			<td style="text-align: center;">敏力SDK</td>
			<td style="text-align: center;">设备版本、系统版本、生成ID、手机样式、手机型号、
        iccid、bssid、MAC地址、IMSI、IMEI、软件列表、 设备序列号、android&nbsp;ID</td>
			<td style="text-align: center;">广告相关服务</td>
			<td style="text-align: center;">开屏启动广告页面</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">
				<a href="http://policy.mediamin.cn:8708/policy.html">官网链接:
					<br>http://www.otthink.cn
					<br>官方隐私政策
					<br>http://policy.otthink.cn:8708/policy.html</a>
			</td>
		</tr>
		<tr>
			<td>深圳市腾讯计算机系统有限公司</td>
			<td style="text-align: center;">QQ&nbsp;</td>
			<td style="text-align: center;">网络访问权限、查看WLAN连接、获取粗略位置、获取精确位置、
        读取外置存储卡、写入外部存储卡、读取手机状态和身份、检索正在运行的应用、查询所有的应用</td>
			<td style="text-align: center;">支持QQ分享服务</td>
			<td style="text-align: center;">支持QQ分享服务</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://open.tencent.com/" rel="nofollow">https://open.tencent.com/</a>
				<br>官方隐私政策
				<br>
				<a href="https://www.tencent.com/zh-cn/privacy-policy.html" rel="nofollow">https://www.tencent.com/zh-cn/privacy-policy.html</a>
			</td>
		</tr>
		<tr>
			<td>深圳市腾讯计算机系统有限公司</td>
			<td style="text-align: center;">微信&nbsp;SDK</td>
			<td style="text-align: center;">本机应用列表、设备型号、操作系统、设备Mac地址、
        唯一设备识别码（IMEI/android&nbsp;ID/IDFA/OPENUDID/GUID、SIM&nbsp;卡&nbsp;IMSI&nbsp;信息）、
        登录IP地址、微信软件版本号、接入网络的方式、类型和状态、网络质量数据、操作日志
				<br>
			</td>
			<td style="text-align: center;">支持微信登录、分享</td>
			<td style="text-align: center;">支持微信登录、分享</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://open.weixin.qq.com/" rel="nofollow">https://open.weixin.qq.com/</a>
				<br>官方隐私政策
				<br>
				<a href="https://www.tencent.com/zh-cn/privacy-policy.html" rel="nofollow">https://www.tencent.com/zh-cn/privacy-policy.html</a>
			</td>
		</tr>
		<tr>
			<td>广州市蜂擎信息科技有限公司</td>
			<td style="text-align: center;">好单库</td>
			<td style="text-align: center;">本机应用列表、设备Mac地址、唯一设备识别码
        （IMEI/android&nbsp;ID/IDFA/OPENUDID/GUID、SIM&nbsp;卡&nbsp;IMSI&nbsp;信息）、
        运营商信息、网络状态信息、操作日志</td>
			<td style="text-align: center;">使用【好单库】服务，支持商品购买</td>
			<td style="text-align: center;">我的页面使用购物领券</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="http://bianxian.haodanku.com?invite_code=35c319635d" rel="nofollow">http://bianxian.haodanku.com?invite_code=35c319635d</a>
				<br>官方隐私政策
				<br>
				<a href="https://bianxian.haodanku.com/index/privacy" rel="nofollow">https://bianxian.haodanku.com/index/privacy</a>
			</td>
		</tr>
    <tr>
      <td>每日互动股份有限公司</td>
			<td style="text-align: center;">个推用户运营SDK</td>
			<td style="text-align: center;">设备信息（IMEI/Mac/Android ID
/IDFA/OPENUDID/GUID/SIM 卡 IMSI /地理位置/设备序列号等）</td>
			<td style="text-align: center;">用户使用分享</td>
			<td style="text-align: center;">一键分享服务、统计分析服务并通过地理位置校准报表数据准确性，提供基础反作弊能力</td>
			<td style="text-align: center;">SDK本机采集</td>
			<td style="text-align: center;">官网链接:
				<br>
				<a href="https://www.getui.com/" rel="nofollow">https://www.getui.com/</a>
				<br>官方隐私政策
				<br>
				<a href="https://docs.getui.com/privacy/" rel="nofollow">https://docs.getui.com/privacy/</a>
			</td>
    </tr>

	</tbody>
</table>
  </div>
</template>
<script>
export default {
  name: 'InfomationBm',
  computed: {
    appName() {
      return this.$route.query?.appName || '有鱼记账';
    },
    appType() {
      return this.$route.query?.appType || 1;
    },
    isIos() {
      return /i(os|phone|pad)/i.test(navigator.userAgent);
    },
  },
  mounted() {
    document.title = `${this.appName}与第三方共享个人信息清单`;
  },
};
</script>
<style lang="scss" scoped>
  .page {
    padding: 40px 20px;
  }
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 25px 0;
    thead {
      background: #f9f9f9;
    }
    td, th {
      min-width: 40px;
      height: 30px;
      border: 1px solid #ccc;
      vertical-align: top;
      padding: 2px 4px;
      text-align: left;
      // box-sizing: border-box;
      vertical-align: top;
      border: 1px #ddd solid;
      padding: 1px 8px;
      word-break:break-all;
    }
    tr:first-child > td {
      background: rgb(239, 239, 239);
    }
  }
  .content {
    font-size: 26px;
    line-height: 38px;
  }
</style>
